import Echo from "laravel-echo";


let io = require('socket.io-client');

let host = `${window.location.hostname}`

// For production no use port
if (process.env.MIX_APP_DEBUG) {
    let port = window.laravel_echo_port ? window.laravel_echo_port : "6002" ;
    host = `${window.location.hostname}:${port}`
}

if (typeof io !== 'undefined') {
    window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: host,
    });
}
